/**
 * 工作任务
 * @type {{}}
 */
import Vue from "vue";
import {
    Input,
    Icon,
    Select,
    Option,
    Message,
    Table,
    Page,
    Modal,
    Button,
    Switch,
    Dropdown,
    DropdownMenu, DropdownItem,
    Cascader,
    Poptip
} from "view-design";
import { taskListColumns,
    taskStatus,
    tasksType,
    getTaskListService,
    editTaskListService,
    getWorkflowListService,
    delTaskListService,
    updateTaskStatusService,
    getTaskClassAllService,
    addTaskClassService,
    delTaskTypeService,
    upgradeTaskTypeService,
    updateTaskClassService
} from '@/service/workflow-service'
import { mapMutations, mapState } from "vuex";
import vueQr from "vue-qr";
Vue.component('Input', Input);
Vue.component('Icon', Icon);
Vue.component('Select', Select);
Vue.component('Option', Option);
Vue.component('Table', Table);
Vue.component('Page', Page);
Vue.component('Modal', Modal);
Vue.component('Button', Button);
Vue.component('onSwitch', Switch);
Vue.component('Dropdown', Dropdown);
Vue.component('DropdownMenu', DropdownMenu);
Vue.component('DropdownItem', DropdownItem);
Vue.component('Cascader', Cascader);
Vue.component('Poptip', Poptip);
import defaultAvatar from '@/assets/Image/default-avatar.png'
import { saveCode } from '@/utils/tools'
import html2canvas from 'html2canvas'
const TaskList = {
    name:'TaskList',
    components:{ vueQr },
    computed: {
        ...mapState({
            menuVisible: state => state.system.menuVisible, //菜单显示状态
            taskInspectionTypeId: state => state.system.taskInspectionTypeId, //分类id
        }),
    },
    data() {
        return {
            taskLoading:false, //loading
            taskListColumns:taskListColumns, //列表显示字段
            defaultAvatar:defaultAvatar,
            taskListArray:{}, //列表数据
            taskStatus:taskStatus, //任务状态
            tasksType:tasksType, //任务类型
            tasksTypeDisabled:false, //任务类型disabled
            taskModalVisible:false, //新增/编辑弹框状态
            editLoading:false, //编辑loading
            selectAllCancel:[], //全选时的临时存储
            workflowListArray:[], //工作流列表
            btnStatus:false, //按钮状态
            qrCodeVisible:false, //二维码modal状态
            deviceListArray:[],
            QRCode:'',
            tasksName:'',
            logoSrc:require("@/assets/Icon/logo-img.png"),
            deviceListHeight:0, //表格高度
            windowWidth:0, //窗口宽度
            selectedMenuId:'',
            taskEditParams:{
                tasksName:'',//任务名称
                flowId:'', //工作流id
                id:'',
                status:'', //发布状态
                classificationId:[],
                tasksType:1, //任务类型
                anew:0, //执行任务状态
            },
            taskListParams:{ //提交的数据集
                tasksName:'',  //任务名称
                flowName:'', //引用流程
                status:'', //任务状态
                tasksType:1, //任务类型
                classificationId:'',
                currentPage: 1,  //当前页
                displayNumber:10 // 显示条数
            },
            taskClassName:'',
            taskClassId:'',
            taskModalClassVisible:false,
            taskClassLoading:false,
        }
    },
    created() {
        this.onTaskList().then()
        this.onTableHeight()
        this.onTaskClassAllService().then()
        this.SET_TASK_TYPE(1)
    },
    mounted() {
        window.addEventListener('resize', this.onTableHeight)
    },
    methods: {
        ...mapMutations('system',['SET_FLOW_ID','SET_TASK_INSPECTION_TYPE_ID','SET_TASK_TYPE','SET_TASK_EDIT']),
        /**
         * 计算table高度
         */
        onTableHeight(){
            this.deviceListHeight = window.innerHeight - 215
            this.windowWidth = window.innerWidth
        },
        /**
         * 重置
         */
        onReset() {
            this.taskListParams.tasksName = ''
            this.taskListParams.flowName = ''
            this.taskListParams.status = ''
        },
        /**
         * 搜索
         */
        onSearch(){
            this.onTaskList().then()
        },
        /**
         * 新增
         */
        onAddList() {
            this.SET_TASK_EDIT('')
            this.$router.push('/workflow/edit/task')
        },
        /**
         * 编辑
         * @param obj
         */
        onEditList(obj){
            this.SET_TASK_EDIT(obj)
            this.$router.push('/workflow/edit/task')
        },
        /**
         * 跳转携带id
         * @param id
         */
        onLinks(id){
            this.SET_TASK_INSPECTION_TYPE_ID(id)
            this.taskListParams.classificationId = id
            this.onTaskList().then()
        },
        /**
         * 添加任务分类
         */
        onAddTaskClass(){
            this.taskModalClassVisible = true
            this.btnStatus = true
            this.taskClassName = ''
            this.taskClassId = ''
        },
        /**
         * 添加二级任务分类
         * @param id
         */
        onChildAddTaskClass(id){
            this.btnStatus = true
            this.taskModalClassVisible = true
            this.taskClassName = ''
            this.taskClassId = id
        },
        /**
         * 添加任务分类验证
         */
        onAddTaskClassCheck(){
            if(this.taskClassName === ''){
                Message.warning('请输入任务分类名称！')
                return;
            }
            let flag = false
            for(let i in this.deviceListArray){
                if(this.deviceListArray[i].name === this.taskClassName){
                    flag = true
                    break;
                }else if(this.deviceListArray[i].children.length!==0){
                    for(let j in this.deviceListArray[i].children){
                        if(this.deviceListArray[i].children[j].name === this.taskClassName){
                            flag = true
                            break;
                        }else if(this.deviceListArray[i].children[j].children.length!==0){
                            for(let z in this.deviceListArray[i].children[j].children){
                                if(this.deviceListArray[i].children[j].children[z].name === this.taskClassName){
                                    flag = true
                                    break;
                                }
                            }
                        }
                    }
                }
            }
            if(flag){
                Message.warning('请勿重复添加任务分类名称！')
                return
            }
            this.btnStatus?this.onAddTaskClassService().then():this.onUpdateTaskClassService().then()

        },
        /**
         * 更新状态验证
         * @param obj
         * @param status
         */
        onUpdateStatusCheck(obj,status) {
            if(status === 0 && obj.status === 3){
                Modal.confirm({
                    title: '提示',
                    content: '<p>进行中的任务不可撤回，待任务完成后可以撤回，若无需再执行次任务，可选择"关闭任务"，此任务将用不启用。</p>',
                    okText: '关闭任务',
                    cancelText: '取消',
                    onOk: () => {
                        this.onUpdateTaskStatus(obj,status).then()
                    },
                });
            }else{
                this.onUpdateTaskStatus(obj,status).then()
            }
        },
        /**
         * 升级设备分类
         * @param id
         */
        onUpgradeTask(id){
            this.onUpgradeTaskType(id).then()
        },
        /**
         * 修改任务分类
         * @param obj
         */
        onUpdateType(obj){
            this.btnStatus = false
            this.taskModalClassVisible = true
            this.taskClassName = obj.label
            this.taskClassId = obj.value
        },
        /**
         * 跳转流程页面
         * @param obj
         */
        onFlowConfig(obj) {
            this.SET_FLOW_ID(obj.flowId)
            this.$router.push('/workflow/config')
        },
        /**
         * 提交验证
         */
        onSubmitCheck(status) {
            if(this.taskEditParams.classificationId.length === 0){
                Message.error('请选择任务分类！');
                return;
            }
            if(this.taskEditParams.tasksName === ''){
                Message.error('请填写任务名称！');
                return;
            }
            if(this.taskEditParams.flowId === '' ||  !this.taskEditParams.flowId){
                Message.error('请选择引用流程！');
                return;
            }
            this.taskEditParams.status = status
            this.onEditTaskList().then()
        },
        /**
         * 删除
         * @param id
         */
        onDelete(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelTaskList(id).then()
                }
            });
        },
        /**
         * 删除任务分类
         * @param id
         */
        onDelTask(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>您确定要删除这条信息吗？</p>',
                onOk: () => {
                    this.onDelTaskType(id).then()
                }
            });
        },
        /**
         * 查看二维码
         * @param row
         */
        onCode(row){
            this.QRCode = row.id
            this.tasksName = row.tasksName
            this.qrCodeVisible = true
        },
        /**
         * 下载图片
         */
        onDowns(){
            html2canvas(this.$refs.taskRef,{
                backgroundColor: null
            }).then((canvas) => {
                let dataURL = canvas.toDataURL("image/png");
                saveCode(this.tasksName,dataURL)
            });
        },
        /**
         * 重置密码提示
         * @param id
         */
        onResetPassword(id) {
            Modal.confirm({
                title: '提示',
                content: '<p>重置密码,将密码设置为手机后6位!</p>',
                onOk: () => {
                    this.onResetUserPassword(id).then()
                }
            });
        },
        /**
         * 替换属性名
         * @param data
         */
        onReplaceAttribute(data){
            let item = []
            data.map(list => {
                let newData = {}
                newData.label = list.name;
                newData.value = list.id;
                newData.visible = list.visible;
                if(list.childNode){
                    newData.children = this.onReplaceAttribute(list.childNode)
                }
                item.push(newData)
            })
            return item
        },
        /**
         * 发送当前页
         * @param number
         */
        onSearchPage(number){
            this.taskListParams.currentPage = number
            this.onTaskList().then()
        },
        /**
         * 发送每页显示的条数
         * @param number
         */
        onSearchPageSize(number){
            this.taskListParams.displayNumber = number
            this.onTaskList().then()
        },
        /**
         * 获取任务列表
         */
        async onTaskList() {
            try {
                this.taskLoading = true
                this.taskListArray = {}
                let params = {
                    tasksName:this.taskListParams.tasksName,
                    flowName:this.taskListParams.flowName,
                    status:this.taskListParams.status,
                    tasksType:1,
                    classificationId:this.taskInspectionTypeId === '' || this.taskInspectionTypeId === undefined?'':this.taskInspectionTypeId,
                    size:this.taskListParams.displayNumber,
                    page:this.taskListParams.currentPage
                }
                const { code,data } = await getTaskListService(params)
                if(code === 'SUCCESS'){
                    this.taskListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.taskLoading = false
            }catch (error){
                this.taskLoading = false
                console.log(error)
            }
        },
        /**
         * 编辑任务
         */
        async onEditTaskList() {
            try {
                this.editLoading = true
                let typeId
                if(this.taskEditParams.classificationId.length === 1){
                    typeId = this.taskEditParams.classificationId[0]
                }
                if(this.taskEditParams.classificationId.length === 2){
                    typeId = this.taskEditParams.classificationId[1]
                }
                if(this.taskEditParams.classificationId.length === 3){
                    typeId = this.taskEditParams.classificationId[2]
                }
                let params = {
                    id:this.taskEditParams.id,
                    classificationId:typeId,
                    tasksName:this.taskEditParams.tasksName,
                    flowId:this.taskEditParams.flowId,
                    status:this.taskEditParams.status,
                    tasksType:1,
                    anew:this.taskEditParams.anew
                }
                const { code } = await editTaskListService(params)
                if(code === 'SUCCESS'){
                    this.taskModalVisible = false
                    this.taskEditParams.classificationId = []
                    this.onTaskList().then()

                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.editLoading = false
            }catch (error){
                this.editLoading = false
                console.log(error)
            }
        },
        /**
         * 获取工作流列表
         */
        async onWorkflowList() {
            try {
                const { code,data } = await getWorkflowListService()
                if(code === 'SUCCESS'){
                    this.workflowListArray = data
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除任务列表
         */
        async onDelTaskList(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await delTaskListService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onTaskList().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 删除任务分类
         */
        async onDelTaskType(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await delTaskTypeService(params)
                if(code === 'SUCCESS'){
                    Message.success('删除成功！')
                    this.onTaskClassAllService().then()
                }else if(code === 'WORK_TASKS_CLASSIFICATION_NODE_HAVE_CHILD_NODE_FAIL'){
                    Message.error('此分类下存在其他分类，不可删除！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 升级任务分类
         */
        async onUpgradeTaskType(id) {
            try {
                let params = {
                    id:id
                }
                const { code } = await upgradeTaskTypeService(params)
                if(code === 'SUCCESS'){
                    Message.success('升级成功！')
                    this.onTaskClassAllService().then()
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 更新任务状态
         */
        async onUpdateTaskStatus(obj,status) {
            try {
                let params = {
                    id:obj.id,
                    status:status
                }
                const { code,data } = await updateTaskStatusService(params)
                if(code === 'SUCCESS'){
                    for(let i in this.taskListArray.content){
                        if(this.taskListArray.content[i].id === obj.id){
                            this.taskListArray.content[i].status = data.status
                        }
                    }
                    status === 0? Message.success('成功撤回！'): Message.success('成功发布！')
                }else if(code === 'WORK_FOLW_UNPUBLISH'){
                    Message.warning('任务流程未发布!')
                }else if(code === 'WORK_FOLW_IS_NULL '){
                    Message.warning('任务流程不存在!')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 获取所有设备分类
         */
        async onTaskClassAllService() {
            try {
                let params = {
                    tasksType:1
                }
                const { code,data } = await getTaskClassAllService(params)
                if(code === 'SUCCESS'){
                    for(let i in data){
                        data[i].visible = true

                        if(data[i].childNode.length!==0){
                            for(let j in data[i].childNode){
                                data[i].childNode[j].visible = true
                            }
                        }
                    }
                    this.deviceListArray = this.onReplaceAttribute(data)
                    console.log(this.deviceListArray)
                }else{
                    Message.error('请求异常，请联系管理员！')
                }

            }catch (error){
                console.log(error)
            }
        },
        /**
         * 添加任务分类
         * @returns {Promise<void>}
         */
        async onAddTaskClassService(){
            try {
                this.taskClassLoading = true
                let params = {
                    tasksType:1,
                    parentId:this.taskClassId,
                    name:this.taskClassName,
                }
                const { code } = await addTaskClassService(params)
                if(code === 'SUCCESS'){
                    this.taskClassId = ''
                    this.taskClassName = ''
                    Message.success('添加成功！')
                    this.onTaskClassAllService().then()
                    this.taskModalClassVisible = false
                }else if(code === 'WORK_TASKS_CLASSIFICATION_NODE_REPEAT_NAME'){
                    Message.error('请勿重复添加任务分类名称！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.taskClassLoading = false

            }catch (error){
                this.taskClassLoading = false
                console.log(error)
            }
        },
        /**
         * 修改任务分类
         * @returns {Promise<void>}
         */
        async onUpdateTaskClassService(){
            try {
                this.taskClassLoading = true
                let params = {
                    id:this.taskClassId,
                    parentId:'',
                    newName:this.taskClassName,
                }
                const { code } = await updateTaskClassService(params)
                if(code === 'SUCCESS'){
                    this.taskClassId = ''
                    this.taskClassName = ''
                    Message.success('修改成功！')
                    this.onTaskClassAllService().then()
                    this.taskModalClassVisible = false
                }else if(code === 'WORK_TASKS_CLASSIFICATION_NODE_REPEAT_NAME'){
                    Message.error('请勿重复添加任务分类名称！')
                }else{
                    Message.error('请求异常，请联系管理员！')
                }
                this.taskClassLoading = false

            }catch (error){
                this.taskClassLoading = false
                console.log(error)
            }
        },
    },
    destroyed () {
        window.removeEventListener('resize',this.onTableHeight)
    }
}
export default TaskList
